<template>
	<div id="page" :style="bg ? `background-image: url('${bg}');` : ''">
		<div class="content" v-if="num!=0">
			<div class="content-label">
				<van-row class="LuckUser" justify="center" align="center" style="height:80%">
					<van-col span="8" v-for="index in num" :key="index" >
						<luckUser 
							:ref="setLuckUserRef"
							:userList='userList'
						/>
					</van-col>
				</van-row>
				<van-row >
					<van-col offset="10" span="4" v-show="init">
						<img v-show="isStart==false" @click="start" class="btn" src="../assets/luckdraw_start.png" />
						<img v-show="isStart==true" @click="stop" class="btn" src="../assets/luckdraw_stop.png" />
						<img v-show="showBack" @click="back" class="btn" src="../assets/luckdraw_back.png" />
					</van-col>
				</van-row>
			</div>
		</div>
		<template v-if="menuItems">
		<toggle-menu
			position="RB"
			:menuItems="menuItems"
            @clickMenu="clickMenu"
			menuBg="#2A3E53"
			itemBg="#2A3E53"
        />
		</template>
	</div>
</template>

<script>
import { reactive, toRefs,getCurrentInstance ,onMounted,onUnmounted } from 'vue';
import { get,post,postWithJson } from '../library/serviceUtil';
import { useRouter } from 'vue-router';
import toggleMenu from '../components/toggleMenu';
import luckUser from '../components/luckUser';
import Menu from '../library/Menu';
import Common from '../library/Common';
import { Toast } from 'vant';

export default {
	name: 'runLuckDraw',
	components: {
		toggleMenu,
		luckUser
	},
    setup(){
		const common = new Common();
		const router = useRouter();
		const { proxy } = getCurrentInstance();
		const menu = new Menu("runLuckDraw",router,common);

		let activity = null;
		const index = proxy.$root.$route.params.index;
		const lottery_index = proxy.$root.$route.params.lottery_index;
		const number = proxy.$root.$route.params.number;
		const bg = proxy.$root.$route.params.bg;
		const bg_music = proxy.$root.$route.params.bg_music;
		menu.setMusic(bg_music);
		
		let luckUserRefs = [];
		let state = reactive({
			menuItems:null,
			num:parseInt(number),
			isStart:false,
			showBack:false,
			bg:bg,
			init:false,
			interval:null,
			runStartsetTimeout:null,
			userList:[
				/*{telphone:"13450283213",user:"ken"},
				{telphone:"13345283213",user:"colaftc"},
				{telphone:"13545875289",user:"waston"},
				{telphone:"13345283432",user:"tom"},
				{telphone:"15764528349",user:"cat"},
				{telphone:"15764524328",user:"mouse"},*/
			],
		});

		const clickMenu = function(item){
			menu.click(item);
		}

		const getUser = async()=>{
			var res = await get(`activity/program/tickets/?program_id=${activity.lottery.id}`);
			if(res.data){
				state.userList = res.data.map((item)=>{
					return {
						telphone:common.highTelphoneNum(item.phone),
						user:item.name,//common.highTelphoneName()
					}
				});
				if(state.userList.length>0){
					return true;
				}
			}
			return false;
		}

		const start = async()=>{
			if(!state.init){
				router.back(-1);
				return ;
			}
			if(state.isStart) return;

			state.isStart = true;
			for(let index in luckUserRefs){
				let item = luckUserRefs[index];
				item.start();
			}
				
		}

		const stop = async()=>{
			state.isStart = null;
			var params = {
					program_id:activity.lottery.id,
					index:index,
					count:number,
				}
			try{
				var res = await postWithJson("/activity/program/lottery/go/",params);
			}catch(error){
				if(error.response.status == 403){
                    Toast("抽奖票数已用完");
                }else{
					Toast(`其他错误 other error ${error.response.status}`);
				}
				state.isStart = true;
				return ;
			}
			var winners = res.data.winners.map((item)=>{
				var ticket = item.ticket;
					return {
						telphone:common.highTelphoneNum(ticket.phone),
						user:ticket.name//common.highTelphoneName()
					}
			});
			var winnersIndex = 0;
			state.interval = setInterval(() => {
				//写入中奖用户
				luckUserRefs[winnersIndex].stop(winners[winnersIndex]);
				winnersIndex++;
				if(winnersIndex == winners.length){
					clearInterval(state.interval);
					state.showBack = true;
				}
			}, 500);
		}
		
		const back  = function(){
			router.push({
				name: 'luckDraw',
				params:{
					index:lottery_index,
				}
			});
		}
		const setLuckUserRef = (el) => {
			luckUserRefs.push(el);
		};

		onUnmounted(()=>{
			clearInterval(state.interval);
			clearTimeout(state.runStartsetTimeout);
			for(var item of luckUserRefs){
				if(item){
					item.close();
				}
			}	
		});

		onMounted(async()=>{
			Toast.loading({
				message: 'loading...',
				forbidClick: false,
				closeOnClick:false,
				duration:0,
			});
			await menu.init();
			state.menuItems = menu.menuItems;
			activity = common.getLocal("activity");
			state.runStartsetTimeout = setTimeout(async()=>{
				if(await getUser()){
					Toast.clear();
					state.init = true;
					start();
				}else{
					Toast.clear();
					state.init = false;
					Toast("获取用户信息失败");
				}
			},500);
		})
		return {
			...toRefs(state),
			clickMenu,
			start,
			stop,
			luckUserRefs,
      		setLuckUserRef,
			back
			
		};
	}
}
</script>
<style scoped>
#page{
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-image: url('../assets/bg.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
	cursor:default;
}
.content{
	position:absolute;
	top:15%;
	left:10%;
	height:70%;
	width:80%;
	align-items:center;
}
.content-label{
	width:100%;
	height:100%;
	background-image: url('../assets/luck_use_list.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
}
.LuckUser{
	padding-top:0.5rem;
}
.btn{
	width: 100%;
}
</style>